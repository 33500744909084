.textarea {
    textarea { /* stylelint-disable-line at-rule-blacklist */
        width: 100%;
        padding: spacing(2);

        color: color(black);

        background: color(white);
        border: 1px solid color(grey-400);
        border-radius: 4px;

        resize: none;

        &:disabled { /* stylelint-disable-line selector-max-type */
            color: color(grey-900);

            cursor: no-drop;
        }
    }
}
