.date-input {
    position: relative;

    display: flex;
    align-items: center;

    width: 100%;

    input { /* stylelint-disable-line selector-max-type */
        width: 100%;
        height: 43px;
        padding: spacing(2);
        padding-left: spacing(10);

        color: color(black);

        background: color(white);
        border: 1px solid color(grey-400);
        border-radius: 4px;

        cursor: pointer;

        &:disabled { /* stylelint-disable-line selector-max-type */
            color: color(grey-900);

            cursor: no-drop;
        }
    }

    .date_input__icon {
        position: absolute;

        left: spacing(3);

        width: spacing(4);

        color: color(grey-800);

        pointer-events: none;
    }
}
