.notes {
    max-height: spacing(96);
    overflow-y: auto;
}

.note {
    padding: spacing(5) 0;

    + .note {
        border-top: 1px solid color(grey-300);
    }

    &:first-child {
        padding-top: 0;
    }

    &:last-child {
        padding-bottom: 0;
    }
}

.note__head {
    display: flex;
    align-items: center;
}

.note__head--unread {
    &:after {
        display: block;
        width: 6px;
        height: 6px;

        margin-left: spacing(2);

        background: color(red-600);

        border-radius: border-radius(xs);

        content: '';
    }
}

.note__profile {
    width: spacing(5);
    height: spacing(5);

    color: color(grey-500);
}

.note__user {
    margin-left: spacing(2);

    color: color(black);
    font-weight: $sans-serif-semibold;
    font-size: font-size(sm);
}

.note__date {
    margin-left: spacing(2);

    color: color(grey-800);
    font-weight: $sans-serif-regular;
    font-size: font-size(sm);
}

.note__body {
    margin-top: spacing(2);

    color: color(black);
    font-weight: $sans-serif-regular;
    font-size: font-size(sm);
}

.note__unread {
    width: 6px;
    height: 6px;
    margin-left: spacing(2);

    background: color(red-600);

    border-radius: 50%;
}
