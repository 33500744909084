.dropdown {
    position: relative;

    display: inline-block;
}

.dropdown--empty {
    display: none;
}

.dropdown__trigger {
    //
}

.dropdown__content {
    position: absolute;
    top: 100%;
    z-index: 1;

    min-width: 170px;

    margin-top: spacing(2);

    padding-top: spacing(1);
    padding-bottom: spacing(1);

    background: color(white);
    border: solid rem-calc(1px) color(grey-400);
    border-radius: spacing(1);

    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.01), 0 6px 10px 0 rgba(0, 0, 0, 0.09);
}

.dropdown__content--form {
    min-width: spacing(64);
    padding: spacing(5) spacing(4);
}

.dropdown__content--padded {
    padding: spacing(5) spacing(4);
}

.dropdown--left {
    .dropdown__content {
        left: 0;

        margin-left: spacing(2);

        &:before {
            position: absolute;
            top: -7px;
            left: 0;

            width: 10px;
            height: 10px;

            background: url('/images/icons/dropdown/arrow.svg') no-repeat top;
            transform: translateX(100%);

            content: '';
        }
    }
}

.dropdown--right {
    .dropdown__content {
        right: 0;

        margin-right: spacing(2);

        &:before {
            position: absolute;
            top: -7px;
            right: 0;

            width: 10px;
            height: 10px;

            background: url('/images/icons/dropdown/arrow.svg') no-repeat top;
            transform: translateX(-100%);

            content: '';
        }
    }
}

.dropdown--bottom,
.dropdown--center {
    .dropdown__content {
        &:before {
            position: absolute;
            top: -7px;

            width: 100%;
            height: 10px;

            background: url('/images/icons/dropdown/arrow.svg') no-repeat top;

            content: '';
        }
    }
}

.dropdown__link {
    display: block;
    padding: spacing(2);

    font-size: font-size(xs);

    text-decoration: none;

    &:hover {
        text-decoration: none;

        background-color: color(grey-100);
        cursor: pointer;
    }
}

button.dropdown__link { /* stylelint-disable-line selector-max-type, selector-no-qualifying-type */
    width: 100%;

    text-align: left;

    background: none;
    border: none;
}
