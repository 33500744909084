.page-header {
    padding: spacing(5) 0;

    background-color: color(white);

    border-bottom: 1px solid color(grey-300);
    box-shadow: 0 rem-calc(2px) rem-calc(4px) 0 rgba(0, 0, 0, 0.05);
}

.page-header__head {
    display: flex;
    gap: spacing(4);

    align-items: center;
    justify-content: space-between;
}

.page-header__head-left,
.page-header__head-right {
    display: flex;
    align-items: center;
}

.page-header__head-left {
    justify-content: flex-start;
}

.page-header__head-right {
    justify-content: flex-end;
}

.page-header__content {
    margin-top: spacing(6);
}

.page-header__buttons {
    display: flex;
    align-items: center;

    > * + * { /* stylelint-disable-line selector-max-universal */
        margin-left: spacing(3);
    }
}

@include respond-to('2xl') {
    .page-header__head-left,
    .page-header__head-right {
        flex-basis: 50%;
    }

    .page-header {
        padding: spacing(8) 0;
    }
}
