.large-create-buttons {
    display: flex;
    flex-direction: column;

    > .large-create-button + .large-create-button {
        margin-top: spacing(6);
    }
}

.large-create-button {
    position: relative;

    display: inline-block;

    flex-basis: 50%;

    padding: spacing(4);

    text-decoration: none;

    background: color(white);
    border: 1px solid color(grey-500);
    box-shadow: 0 rem-calc(2px) rem-calc(4px) 0 rgba(0, 0, 0, 0.05);

    &:hover {
        text-decoration: none;

        &:after {
            opacity: 1;
        }
    }

    &:after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        box-shadow: 0 rem-calc(2px) rem-calc(4px) rem-calc(4px) rgba(0, 0, 0, 0.05);

        opacity: 0;

        transition: opacity 200ms transition-timing();

        content: '';
    }
}

.large-create-button__head {
    display: flex;
    align-items: center;
}

.large-create-button__icon {
    width: 12px;
    height: 12px;

    color: color(grey-800);
}

.large-create-button__title {
    margin-left: spacing(1);

    color: color(black);

    font-weight: $sans-serif-semibold;
    font-size: font-size(sm);
}

.large-create-button__description {
    margin-top: spacing(2);

    color: color(grey-800);
    font-weight: $sans-serif-regular;

    font-size: font-size(sm);
}

@include respond-to('lg') {
    .large-create-buttons {
        flex-direction: row;

        > .large-create-button + .large-create-button {
            margin-top: 0;
            margin-left: spacing(8);
        }
    }
}
