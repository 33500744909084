/**
 * Framework
 */
@import 'breakpoint-sass/stylesheets/breakpoint';
@import 'framework/mixins/tests';
@import 'framework/mixins/clearfix';
@import 'framework/mixins/font-smoothing';
@import 'framework/container/functions';
@import 'framework/container/mixins';
@import 'framework/unit/functions';
@import 'framework/color/functions';
@import 'framework/font-size/functions';
@import 'framework/spacing/functions';
@import 'framework/breakpoints/functions';
@import 'framework/breakpoints/mixins';
@import 'framework/grid/mixins';
@import 'framework/grid/functions';
@import 'framework/border-radius/functions';
@import 'framework/transition-timing/functions';

/**
 * Initialise theme
 */
@import 'theme/variables/variables';

@include register-breakpoint-aliases($breakpoints-config);

@import 'theme/variables/css-variables';
@import 'vendor/normalize';
@import 'theme/global/box-sizing';
@import 'theme/global/images';
@import 'theme/global/typography';
@import 'theme/global/tooltips';
@import 'theme/layout/layouts';
@import 'theme/global/alpine';

/**
 * Components etc
 */
@import 'elements';
@import 'components';
@import 'templates';

/**
 * Utilities
 */
@import 'framework/utilities/spacing';
@import 'framework/utilities/overflow';
@import 'framework/utilities/text';
@import 'framework/utilities/display';
// @import 'framework/utilities/max-width';
@import 'framework/utilities/colors';
@import 'framework/utilities/visibility';
// @import 'framework/utilities/list-reset';
// @import 'framework/utilities/grid';
@import 'framework/utilities/font-sizes';
@import 'framework/utilities/flex';

/**
 * Vendor Styles
 */
@import 'vendor';
