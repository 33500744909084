.select {
    position: relative;

    width: 100%;

    select { /* stylelint-disable-line selector-max-type */
        width: 100%;
        height: 43px;
        padding: spacing(2) spacing(8) spacing(2) spacing(2);

        color: color(black);

        background: color(white);
        border: 1px solid color(grey-400);
        border-radius: 4px;

        appearance: none;

        &:disabled { /* stylelint-disable-line selector-max-type */
            color: color(grey-900);

            cursor: no-drop;
        }
    }

    .select__arrow {
        position: absolute;

        top: 0;
        right: spacing(2);

        width: spacing(3);
        height: 100%;

        pointer-events: none;
    }
}
