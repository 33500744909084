$colors-config: (
    pink-100: #fff5f7,
    pink-200: #fed7e2,
    pink-300: #fbb6ce,
    pink-400: #f687b3,
    pink-500: #ed64a6,
    pink-600: #d5408c,
    pink-700: #b83280,
    pink-800: #97266d,
    pink-900: #702459,
    purple-100: #edeaff,
    purple-200: #ddd6fe,
    purple-300: #c4b5fd,
    purple-400: #a78bfa,
    purple-500: #8b5cf7,
    purple-600: #7c3aee,
    purple-700: #6d29d9,
    purple-800: #5b21b6,
    purple-900: #4c1d95,
    indigo-100: #ecf4ff,
    indigo-200: #c3dafe,
    indigo-300: #a3bffa,
    indigo-400: #809cf5,
    indigo-500: #667eea,
    indigo-600: #5a67d8,
    indigo-700: #4c51bf,
    indigo-800: #434190,
    indigo-900: #3d366b,
    teal-100: #e7fffa,
    teal-200: #b2f5ea,
    teal-300: #81e6d9,
    teal-400: #4fd1c5,
    teal-500: #37b2ac,
    teal-600: #309795,
    teal-700: #2c7a7b,
    teal-800: #285e61,
    teal-900: #244e52,
    blue-100: #dcebfe,
    blue-200: #bfdbfe,
    blue-300: #93c5fd,
    blue-400: #61a5fa,
    blue-500: #3b82f6,
    blue-600: #2664ec,
    blue-700: #1d4ed8,
    blue-800: #1e40af,
    blue-900: #1e3a8a,
    green-100: #f4f7e9,
    green-200: #e4ecc6,
    green-300: #c2d37c,
    green-400: #b2cd47,
    green-500: #b5bd00,
    green-600: #7c980d,
    green-700: #3d7c0a,
    green-800: #0b5821,
    green-900: #3b3f4c,
    yellow-100: #ffffef,
    yellow-200: #fefcbf,
    yellow-300: #faf08a,
    yellow-400: #f6e05e,
    yellow-500: #ecc94a,
    yellow-600: #d69e2e,
    yellow-700: #b7791f,
    yellow-800: #975a17,
    yellow-900: #734210,
    orange-100: #fffaf0,
    orange-200: #feebc8,
    orange-300: #fbd48d,
    orange-400: #f6ad54,
    orange-500: #ed8936,
    orange-600: #dd6b1f,
    orange-700: #c05621,
    orange-800: #9c4221,
    orange-900: #7b341e,
    red-100: #fff5f5,
    red-200: #fed7d7,
    red-300: #feb2b2,
    red-400: #ff7d7d,
    red-500: #f56565,
    red-600: #e53e3e,
    red-700: #c53030,
    red-800: #9b2d2c,
    red-900: #742a2a,
    grey-100: #f9fafa,
    grey-200: #f3f4f6,
    grey-300: #eceef0,
    grey-400: #dde0e6,
    grey-500: #cccfd8,
    grey-600: #c0c2cc,
    grey-700: #acaebb,
    grey-800: #9798a6,
    grey-900: #646473,
    transparent: transparent,
    white: #fff,
    black: #262829,
);
