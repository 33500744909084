/**
 * Default Box Sizing
 */
// Revised box model reset
// http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/

/* stylelint-disable property-no-vendor-prefix,selector-max-type,selector-max-universal */
html {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

*,
*:before,
*:after {
    // scss-lint:disable VendorPrefix
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
}
