.declaration__pagination {
    width: 100%;

    padding-bottom: spacing(8);

    .pagination__count {
        display: none;
    }
}

@include respond-to('2xl') {
    .declaration__pagination {

        display: block;
        padding-bottom: 0;

        background-color: color(white);
        box-shadow: 0 -2px 9px 0 rgba(0, 0, 0, 0.05);

        .pagination__count {
            display: block;
        }
    }
}
