.status-badge {
    display: inline-block;
    padding: spacing(1) spacing(2);

    color: color(grey-900);

    font-weight: $sans-serif-semibold;
    font-size: font-size('2xs');
    letter-spacing: 0.2px;

    white-space: nowrap;

    text-transform: uppercase;

    background-color: color(grey-300);
    border: solid 1px currentColor;

    border-radius: border-radius(lg);
}

.status-badge--red {
    color: color(red-600);

    background-color: color(red-100);
}

.status-badge--grey {
    color: color(grey-900);

    background-color: color(grey-300);
}

.status-badge--blue {
    color: color(blue-500);

    background-color: color(indigo-100);
}

.status-badge--orange {
    color: color(orange-500);

    background-color: color(orange-100);
}

.status-badge--green {
    color: color(green-500);

    background-color: color(green-100);
}
