@include breakpoint-set('to ems', true);
@include breakpoint-set('base font size', $em-base);

//
// Provides an easy way to wrap a block with a (min-width to max-width) media query
// Useful when you need to only apply css between two breakpoints
//
// Example:
//
// @include respond-between(md, lg) {
//     .card {
//         margin-top: 0;
//     }
// }
//
// Outputs the following CSS, where the max-width is 1px less than the second provided breakpoint:
//
// @media (min-width: 684px) and (max-width: 767px) {
//     .card {
//         margin-top: 0;
//     }
// }
@mixin respond-between($min-width-breakpoint, $max-width-breakpoint) {
    $min-width: breakpoint-width($min-width-breakpoint);
    $max-width: breakpoint-width($max-width-breakpoint) - 1px;

    @if $min-width-breakpoint == 'xs' {
        @include breakpoint(max-width $max-width) {
            @content;
        }
    } @else {
        @include breakpoint($min-width $max-width) {
            @content;
        }
    }
}
//
// Provides a way to wrap a block with a media query, if it is needed. e.g.
//
// @include wrap-breakpoint('md') {
//     .container {}
// }
//
@mixin wrap-breakpoint($breakpoint-name) {
    $index: breakpoint-index($breakpoint-name);

    @if $index == -1 {
        @error "Could not find breakpoint with name '#{$breakpoint-name}'";
    }
    @if $index == 1 {
        // As we're mobile first, the first breakpoint should be output without a media query wrapper
        @content;
    } @else {
        // All other breakpoints should be wrapped

        @include respond-to($breakpoint-name) {
            /* Breakpoint: #{$breakpoint-name} */
            @content;
        }
    }
}

//
//  Register all our breakpoints so we can refer to them by name, e.g.
//
//  @include respond-to('small') {
//      ...
//  }
//
@mixin register-breakpoint-aliases($config) {
    @for $i from 1 to length($config) {
        $breakpoint: nth($config, $i);

        $media-query: map-get($breakpoint, 'breakpoint');

        @if $media-query {
            @include add-breakpoint(map-get($breakpoint, 'name'), $media-query);
        }
        @if $i == 1 {
            // First
            $next-breakpoint: nth($config, $i + 1);
            $media-query: max-width map-get($next-breakpoint, 'breakpoint');
        } @else {
            $previous-breakpoint: nth($config, $i - 1);

            // All the rest
            $next-breakpoint: nth($config, $i + 1);
            $media-query: map-get($breakpoint, 'breakpoint') (map-get($next-breakpoint, 'breakpoint'));
        }

        @if $media-query {
            @include add-breakpoint(map-get($breakpoint, 'name') + '-only', $media-query);
        }
    }

    // Handle the last breakpoint manually
    $last-breakpoint: nth($breakpoints-config, -1);

    @include add-breakpoint(map-get($last-breakpoint, 'name'), map-get($last-breakpoint, 'breakpoint'));
}
@mixin respond-to-all() {
    @each $breakpoint in breakpoint-names() {
        @include wrap-breakpoint($breakpoint) {
            @content($breakpoint);
        }
    }
}
