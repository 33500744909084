$em-base: 16px;
$rem-base: 16px;
$grid-use-flex: true;

@import './font';
@import './colors';
@import './spacing';
@import './border-radius';
@import './transition-timings';

$typography-tablet-breakpoint: 'lg';
$typography-desktop-breakpoint: '2xl';

$mobile-nav-height: 44px;
$tablet-nav-height: 59px;

$breakpoints-config: (
    ( name: 'xs', breakpoint: null ),
    ( name: 'sm', breakpoint: 500px ),
    ( name: 'md', breakpoint: 650px ),
    ( name: 'lg', breakpoint: 768px ),
    ( name: 'xl', breakpoint: 816px ),
    ( name: '2xl', breakpoint: 972px ),
    ( name: '3xl', breakpoint: 1144px ),
    ( name: '4xl', breakpoint: 1440px )
);
