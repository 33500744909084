.declaration-section-dropdown {
    display: flex;

    gap: spacing(2);
    align-items: center;

    color: color(black);

    font-weight: $sans-serif-semibold;
    font-size: font-size(base);

    background: none;
    border: none;

    appearance: none;
}

.declaration-section-dropdown__icon {
    height: 12px;

    color: color(black);
}
