.input {
    width: 100%;

    input { /* stylelint-disable-line selector-max-type */
        width: 100%;
        height: 43px;
        padding: spacing(2);

        color: color(black);

        background: color(white);
        border: 1px solid color(grey-400);
        border-radius: 4px;

        &:disabled { /* stylelint-disable-line selector-max-type */
            color: color(grey-900);

            cursor: no-drop;
        }
    }
}
