@import '../mixins/output-utilities';

$padding: (
    p: (
        padding,
    ),
    py: (
        padding-top,
        padding-bottom,
    ),
    pt: (
        padding-top,
    ),
    pb: (
        padding-bottom,
    ),
    px: (
        padding-right,
        padding-left,
    ),
    pl: (
        padding-left,
    ),
    pr: (
        padding-right,
    ),
);

$margin: (
    m: (
        margin,
    ),
    my: (
        margin-top,
        margin-bottom,
    ),
    mt: (
        margin-top,
    ),
    mb: (
        margin-bottom,
    ),
    mx: (
        margin-right,
        margin-left,
    ),
    ml: (
        margin-left,
    ),
    mr: (
        margin-right,
    ),
);

@include output-utilities($padding, $spacing-config);
@include output-utilities($margin, $spacing-config);

/* stylelint-disable declaration-no-important */

// @include respond-to-all() using ($breakpoint) {
//     @if $breakpoint == 'xs' {
//         $breakpoint-value: null;
//     }

//     $breakpoint-value: '#{$breakpoint}\\:';

//     //
//     // Margin auto
//     //
//     .#{$breakpoint-value}mx-auto {
//         margin-right: auto !important;
//         margin-left: auto !important;
//     }

//     @each $size, $value in $spacing-config {
//         //
//         // Space Between
//         //
//         .#{$breakpoint-value}space-x-#{$size} > * + * {
//             margin-left: $value !important;
//         }

//         .#{$breakpoint-value}space-y-#{$size} > * + * {
//             margin-top: $value !important;
//         }
//     }
// }
/* stylelint-enable declaration-no-important */
