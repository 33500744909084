@import '../mixins/output-utilities';

$flex: (
    prefix: 'flex',
    utilities: (
        row: (
            property: flex-direction,
            value: row,
        ),
        row-reverse: (
            property: flex-direction,
            value: row-reverse,
        ),
        column: (
            property: flex-direction,
            value: column,
        ),
        column-reverse: (
            property: flex-direction,
            value: column-reverse,
        ),
        no-wrap: (
            property: flex-wrap,
            value: no-wrap,
        ),
        wrap: (
            property: flex-wrap,
            value: wrap,
        ),
        wrap-reverse: (
            property: flex-wrap,
            value: wrap-reverse,
        ),
        initial: (
            property: flex,
            value: 0 1 auto,
        ),
        1: (
            property: flex,
            value: 1 1 0%,
        ),
        auto: (
            property: flex,
            value: 1 1 auto,
        ),
        none: (
            property: flex,
            value: none,
        ),
        grow: (
            property: flex-grow,
            value: 1,
        ),
        grow-0: (
            property: flex-grow,
            value: 0,
        ),
        shrink: (
            property: flex-shrink,
            value: 1,
        ),
        shrink-0: (
            property: flex-shrink,
            value: 0,
        ),
    ),
);

$items: (
    prefix: 'items',
    utilities: (
        stretch: (
            property: align-items,
            value: stretch,
        ),
        start: (
            property: align-items,
            value: flex-start,
        ),
        center: (
            property: align-items,
            value: center,
        ),
        end: (
            property: align-items,
            value: flex-end,
        ),
        baseline: (
            property: align-items,
            value: baseline,
        ),
    ),
);

$content: (
    prefix: 'content',
    utilities: (
        start: (
            property: align-content,
            value: flex-start,
        ),
        center: (
            property: align-content,
            value: center,
        ),
        end: (
            property: align-content,
            value: flex-end,
        ),
        between: (
            property: align-content,
            value: between,
        ),
        around: (
            property: align-content,
            value: around,
        ),
    ),
);

$self: (
    prefix: 'self',
    utilities: (
        auto: (
            property: align-self,
            value: flex-auto,
        ),
        start: (
            property: align-self,
            value: flex-start,
        ),
        center: (
            property: align-self,
            value: center,
        ),
        end: (
            property: align-self,
            value: flex-end,
        ),
        stretch: (
            property: align-self,
            value: stretch,
        ),
    ),
);

$justify: (
    prefix: 'justify',
    utilities: (
        start: (
            property: justify-content,
            value: flex-start,
        ),
        center: (
            property: justify-content,
            value: center,
        ),
        end: (
            property: justify-content,
            value: flex-end,
        ),
        between: (
            property: justify-content,
            value: space-between,
        ),
        around: (
            property: justify-content,
            value: space-around,
        ),
    ),
);

$order-config: (
    first: '-9999',
    last: '9999',
    none: '0',
    normal: '0',
    1: '1',
    2: '2',
    3: '3',
    4: '4',
    5: '5',
    6: '6',
    7: '7',
    8: '8',
    9: '9',
    10: '10',
    11: '11',
    12: '12',
);

@mixin output-flex-utilities($config) {
    // Loop over each breakpoint
    @each $bp in breakpoint-names() {
        $prefix: '#{$bp}\\:';

        @if $bp == 'xs' {
            $prefix: '';
        }

        @include wrap-breakpoint($bp) {
            // Loop over each property
            @each $name, $utility in map-get($config, utilities) {
                $first-char: str-slice($prefix, 1, 1);
                $number: index(('1', '2', '3', '4', '5', '6', '7', '8', '9', '0'), $first-char);

                @if type-of($number) == number {
                    $prefix: '\\3#{$prefix}';
                }

                .#{$prefix}#{map-get($config, prefix)}-#{$name} {
                    #{map-get($utility, property)}: #{map-get($utility, value)};
                }
            }
        }
    }
}

@include output-flex-utilities($flex);
@include output-flex-utilities($items);
@include output-flex-utilities($content);
@include output-flex-utilities($self);
@include output-flex-utilities($justify);
@include output-utilities(
    (
        order: (
            order,
        ),
    ),
    $order-config
);
