/**
 * Images
 */
/* stylelint-disable selector-max-type */
img {
    max-width: 100%;
    height: auto;

    vertical-align: middle;
}
