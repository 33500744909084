.form-section {
    .layout--halves {
        padding: 0;
    }

    .layout--halves + .layout--halves {
        margin-top: spacing(6);
    }

    & + .form-section { /* stylelint-disable-line scss/selector-no-redundant-nesting-selector */
        margin-top: spacing(6);
    }
}

.layout__col {
    .form-section:first-child {
        margin-top: spacing(6);
    }
}
