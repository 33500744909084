.repeater {
    + .field-group {
        margin-top: spacing(6);
    }

    + .repeater {
        margin-top: spacing(6);
    }
}

.repeater__body {
    margin-top: spacing(3);

    + .repeater__foot {
        margin-top: spacing(3);
    }
}

.repeater-row {
    + .repeater-row {
        margin-top: spacing(3);
    }

    background: color(white);
    border: 1px solid color(grey-300);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04);
}

.repeater-row__head {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: spacing(5) spacing(6);

    color: color(black);
    font-weight: $sans-serif-semibold;

    font-size: font-size(base);

    cursor: pointer;
}

.repeater-row__head-left,
.repeater-row__head-right {
    display: flex;
    align-items: center;
}

.repeater-row__arrow {
    width: 10px;

    color: color(grey-900);
}

.repeater-row__body {
    padding: spacing(6);

    border-top: 1px solid color(grey-300);
}

.repeater-row__foot {
    display: flex;
    justify-content: flex-end;

    padding: 0 spacing(6) spacing(6) spacing(6);
}

.repeater-row__delete {
    color: color(red-600);
}

.repeater__head {
    display: flex;
    justify-content: flex-start;

    + .repeater__body {
        margin-top: spacing(6);
    }
}

.repeater__foot {
    display: flex;
    justify-content: flex-end;
}

.repeater__head + .repeater__foot {
    margin-top: spacing(3);
}
