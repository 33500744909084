.section {
    padding-top: spacing(8);
}

.section__head {
    display: none;
    align-items: center;

    margin: spacing(6) 0;
    padding: spacing(4) 0;

    font-size: font-size(xl);

    border-bottom: 1px solid color(grey-300);

    .heading-section {
        margin-left: spacing(2);
    }
}

.section__fields {
    .heading-form {
        margin-top: spacing(6);
    }
}

.section__number {
    display: flex;
    align-items: center;
    justify-content: center;

    width: spacing(6);
    height: spacing(6);
    padding: spacing(1);

    font-weight: $sans-serif-semibold;
    font-size: font-size(sm);

    background: color(white);
    border: 1px solid color(grey-400);
    border-radius: border-radius(lg);
}

@include respond-to('md') {
    .section {
        padding-top: 0;
    }

    .section__head {
        display: flex;
    }
}
@include respond-to('2xl') {
    .section__fields {
        padding-bottom: spacing(24);
    }
}
