$font-family-sans-serif: 'Inter', sans-serif;

$sans-serif-regular: 400;
$sans-serif-medium: 500;
$sans-serif-semibold: 600;
$sans-serif-bold: 700;

$font-size-config: (
    '2xs': rem-calc(11px),
    xs: rem-calc(12px),
    sm: rem-calc(14px),
    base: rem-calc($em-base),
    lg: rem-calc(18px),
    xl: rem-calc(20px),
    '2xl': rem-calc(24px),
    '3xl': rem-calc(30px),
    '4xl': rem-calc(36px),
    '5xl': rem-calc(48px),
    '6xl': rem-calc(60px),
    '7xl': rem-calc(72px),
    '8xl': rem-calc(96px),
    '9xl': rem-calc(128px)
);
