.renewal {
    display: flex;
    flex-direction: column;
    width: 100%;

    background: color(white);
    border: 1px solid color(grey-300);
}

.renewal + .renewal {
    margin-top: spacing(6);
}

.renewal--empty {
    .renewal__body {
        align-items: center;
        justify-content: center;
    }
}

.renewal__head {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: spacing(6);
}

.renewal__head-left,
.renewal__head-right,
.renewal__head-centre {
    display: flex;
    align-items: center;
}

.renewal__head-centre {
    justify-content: center;
    width: 100%;
}

.renewal__open-icon {
    width: 15px;
    height: 15px;

    color: color(grey-700);
}

.renewal__add-button {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;

    padding: spacing(6);

    font-weight: $sans-serif-semibold;
    font-size: font-size(base);

    background: none;
    border: none;

    cursor: pointer;

    opacity: 1;

    appearance: none;

    &:hover {
        opacity: 0.5;
    }
}

.renewal__add-icon {
    width: 15px;
    height: 15px;

    margin-right: spacing(4);

    color: color(grey-700);
}

.renewal__heading {
    margin: 0;
    margin-right: spacing(3);

    font-weight: $sans-serif-semibold;
    font-size: font-size(lg);
}

.renewal__body {
    display: grid;
    grid-gap: spacing(6);
    grid-template-columns: 1fr 2fr 30px;

    padding: spacing(6) spacing(12);

    border-top: 1px solid color(grey-400);

    .checkbox {
        border: none;
    }

    .checkbox__label {
        padding: 0;
    }

    .checkbox__title {
        font-weight: $sans-serif-regular;
    }
}

.renewal__status {

    display: flex;
    align-items: center;
    justify-content: center;

    svg {  /* stylelint-disable-line selector-max-type */
        width: 20px;
        height: 20px;

        color: color(green-500);
    }
}

.renewal__label {
    font-weight: $sans-serif-semibold;
    font-size: font-size(sm);
    text-align: right;
}

.renewal__item {
    .label__title {
        font-weight: $sans-serif-regular;
    }
}

.renewal__foot {
    padding: spacing(5) spacing(6);
}
