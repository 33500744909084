@mixin container($columns: 12) {
    // $ratio: calc(#{$columns} / var(--grid-columns));
    // $fullGridWidth: calc(var(--layout-max-width) - (2 * var(--layout-gutter)) + (1 * var(--grid-gutter)));
    // $width: calc((#{$ratio} * #{$fullGridWidth}) - (1 * var(--grid-gutter)) + (2 * var(--layout-gutter)));
    max-width: container-width($columns: $columns);

    margin-right: auto;
    margin-left: auto;
    padding: 0 rem-calc(20px);
    padding: 0 var(--layout-gutter);
}
