:root {
    --layout-max-width: 100%;
    --layout-gutter: #{spacing(4)};
    --grid-columns: 12;
    --grid-gutter: #{spacing(3)};
}

@include respond-to(lg) {
    :root {
        --layout-gutter: #{spacing(8)};
        --grid-gutter: #{spacing(7)};
    }
}
@include respond-to('3xl') {
    :root {
        --layout-gutter: #{spacing(20)};
    }
}

@each $breakpoint-config in $breakpoints-config {
    $name: map-get($breakpoint-config, name);

    @if ($name != 'xs') {
        @include respond-to($name) {
            :root {
                --layout-max-width: #{map-get($breakpoint-config, breakpoint)};
            }
        }
    }
}
