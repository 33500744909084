// Fluid Grid by Joe Lambert
// (c) 2013 Rareloop Ltd (http://www.rareloop.com)

@use "sass:math";

$grid-use-flex: true !default;

// Map keep track of the placeholders used for common column classes
// $grid-col-placeholder-selectors: ();

// Produce the class for a grid row
// @mixin     grid-row
// @param     $clearfix {Boolean} Whether to enable clearfix for float grids
// @usage:
// @include grid-row()
@mixin grid-row($clearfix: true) {
    @if $grid-use-flex {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    margin-right: 20px;
    margin-right: calc(var(--grid-gutter) * -0.5);
    margin-left: 20px;
    margin-left: calc(var(--grid-gutter) * -0.5);

    @if (not $grid-use-flex) and $clearfix {
        // Clearfix (http://nicolasgallagher.com/micro-clearfix-hack/)
        &:before,
        &:after {
            display: table;

            content: ' ';
        }

        &:after {
            clear: both;
        }
    }

    // font-size: 1rem;
}
@mixin grid-col($count, $colsContainer: false) {
    @if not $grid-use-flex {
        // Properties common to all columns
        float: left;
    }

    box-sizing: border-box;
    padding-right: 20px;
    padding-right: calc(var(--grid-gutter) * 0.5);
    padding-left: 20px;
    padding-left: calc(var(--grid-gutter) * 0.5);

    @if $colsContainer {
        width: 100% * math.div($count, $colsContainer);
    } @else {
        width: grid-col-width($count);
    }
}

// Produce the class to offset a grid column
// @mixin     grid-offset
// @param     $count {Number} The number of columns to offset by
// @usage:
// @include grid-offset(2)
@mixin grid-offset($count, $colsContainer: false) {
    margin-left: 30px;

    @if $colsContainer {
        margin-left: 100% * math.div($count, $colsContainer);
    } @else {
        margin-left: grid-col-width($count);
    }
}

// Creates the required styles to draw grid lines on an element
// The class must be applied to something higher up than the row
// as the row is slightly larger than the container.
@mixin rare-show-grid($col-color: #ccc, $gutter-color: transparent) {
    $bg: linear-gradient(transparent, transparent);

    @for $col from 1 through 12 {
        $bg: join(
            $bg,
            linear-gradient(
                90deg,
                transparent,
                transparent calc(#{$col - 1} * (100% / var(--grid-columns))),
                $gutter-color calc(#{$col - 1} * (100% / var(--grid-columns))),
                $gutter-color calc((#{$col - 1} * (100% / var(--grid-columns))) + (0.5 * var(--grid-gutter))),
                $col-color calc((#{$col - 1} * (100% / var(--grid-columns))) + (0.5 * var(--grid-gutter))),
                $col-color calc((#{$col - 1} * (100% / var(--grid-columns))) + (0.5 * var(--grid-gutter)) + (100% / var(--grid-columns)) - (1 * var(--grid-gutter))),
                transparent calc((#{$col - 1} * (100% / var(--grid-columns))) + (0.5 * var(--grid-gutter)) + (100% / var(--grid-columns)) - (1 * var(--grid-gutter)))
            ),
            comma
        );
    }
    
    position: relative;

    &:before {
        position: absolute;
        top: 0;
        left: calc(-0.5 * var(--grid-gutter));

        z-index: 0;

        width: calc(100% + var(--grid-gutter));
        height: 100%;

        background: $bg;

        content: '';
    }
}
