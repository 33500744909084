.tabs {
    display: none;
    align-items: center;
    margin: 0;
    padding: 0;

    white-space: nowrap;

    list-style: none;

    > * + * { /* stylelint-disable-line selector-max-universal */
        margin-left: spacing(1);
    }
}

.tabs__tab {
    position: relative;

    padding: rem-calc(6px) spacing(4);

    color: color(grey-800);
    font-weight: $sans-serif-semibold;
    font-size: font-size(xs);

    text-align: center;
    text-decoration: none;

    cursor: pointer;
}

.tabs__tab--active {
    color: color(black);

    background-color: color(grey-200);

    border-radius: border-radius(lg);

    &:hover {
        text-decoration: none;
    }
}

.unread {
    position: relative;

    &:after {
        position: absolute;
        top: 0;
        right: -7px;

        display: block;
        width: 6px;
        height: 6px;

        background: color(red-600);

        border-radius: border-radius(xs);

        content: '';
    }
}

.tabs--mobile {
    display: flex;
}

@include respond-to('md') {
    .tabs--mobile {
        display: none;
    }

    .tabs {
        display: flex;
    }
}
