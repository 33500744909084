/* stylelint-disable selector-max-type */

html,
body {
    // Better font antialiasing for Webkit/Blink/Gecko
    @include font-smoothing(on);

    font-family: $font-family-sans-serif;
}

/* stylelint-disable declaration-property-unit-whitelist */

html {
    font-size: 100%;

    animation: smoothscroll1 1s;

    &:focus-within {
        animation-name: smoothscroll2;
        scroll-behavior: smooth;
    }
}

@keyframes smoothscroll1 {
    from,
    to {
        scroll-behavior: smooth;
    }
}
@keyframes smoothscroll2 {
    from,
    to {
        scroll-behavior: smooth;
    }
}

body {
    color: color(black);
    font-weight: $sans-serif-regular;
    font-size: font-size(base);
    font-family: $font-family-sans-serif;
}

a {
    color: color(black);
    text-decoration: underline;

    &:hover {
        text-decoration: underline;
    }
}

p {
    margin: 0;
}

strong {
    font-weight: $sans-serif-bold;
}

.heading-modal,
.heading-1,
.heading-2,
.heading-3,
.heading-4,
.heading-form,
.heading-repeater,
.heading-section {
    display: block;
    margin-top: 0;
    margin-bottom: 0;

    color: color(black);

    font-weight: $sans-serif-semibold;
    font-family: $font-family-sans-serif;
}

.heading-1 {
    font-size: font-size('6xl');
}

.heading-2 {
    font-size: font-size('5xl');
}

.heading-3 {
    font-size: font-size('3xl');
}

.heading-4 {
    font-size: font-size('2xl');
}

.heading-repeater {
    margin: spacing(6) 0;
    padding: spacing(4) 0;

    font-size: font-size(lg);

    border-bottom: 1px solid color(grey-300);
}

.heading-section {
    font-size: font-size(xl);
}

.heading-form {
    display: flex;
    align-items: center;

    padding: spacing(4) 0;

    font-size: font-size(lg);

    border-bottom: 1px solid color(grey-300);

    + * { /* stylelint-disable-line selector-max-universal */
        margin-top: spacing(6);
    }
}

.heading-form__icon {
    width: spacing(6);
    margin-right: spacing(2);

    color: color(grey-800);
}

.heading-modal {
    font-weight: $sans-serif-medium;
    font-size: font-size('2xl');
}

/* stylelint-enable declaration-property-unit-whitelist */
