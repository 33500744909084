/* stylelint-disable rareloop/no-nested-breakpoints */

.layout-html {
    width: 100%;
    height: 100%;
}

.layout-body {
    display: flex;
    height: 100%;

    background: color(grey-100);
}

.layout-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
}

.layout-main {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding-bottom: spacing(10);

    > * { /* stylelint-disable-line selector-max-universal */
        width: 100%;
    }
    overflow-y: auto;
}

.layout-main--no-bottom-padding {
    padding-bottom: spacing(0);
}

.layout--center {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    height: 100%;

    > * { /* stylelint-disable-line selector-max-universal */
        flex-grow: 1;
        margin-top: spacing(4);
        margin-bottom: auto;

        @include respond-to('md') {
            margin-top: auto;
        }
    }
}

.layout--fluid {
    width: 100%;
}

.layout--contained {
    @include container($columns: 12);

    .layout--4\/8 {
        padding: 0;
    }
}

.layout--full-height {
    flex-grow: 1;
}

.layout--contained-col-6 {
    @include container($columns: 12);
    @include respond-to('2xl') {
        @include container($columns: 6);
    }
}

.layout--halves {
    @include container($columns: 12);

    .layout__col {
        margin-top: spacing(6);

        &:first-child {
            margin-top: 0;
        }
    }

    @include respond-to('2xl') {
        .layout__row {
            @include grid-row;
        }

        .layout__col {
            @include grid-col(6);

            height: auto;
            margin-top: var(--grid-gutter);

            &:nth-child(-n + 2) {
                margin-top: 0;
            }
        }
    }
}

.layout--4\/8 {
    @include container($columns: 12);
    @include respond-to('2xl') {
        .layout__row {
            @include grid-row;
        }

        .layout__col {
            &:first-child {
                @include grid-col(4);
            }

            &:last-child {
                @include grid-col(8);
            }
        }
    }
}

.panel__body {
    .layout--halves {
        padding: 0;
    }
}

.layout--halves { /* stylelint-disable-line no-duplicate-selectors */
    + .field-group {
        margin-top: spacing(6);
    }
}

.field-group {
    + .layout--halves {
        margin-top: spacing(6);
    }
}

/* stylelint-enable rareloop/no-nested-breakpoints */
