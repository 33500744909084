$display: (
    flex: flex,
    block: block,
    inline: inline,
    inline-block: inline-block,
);

// Loop over each breakpoint
@each $bp in breakpoint-names() {
    $prefix: '#{$bp}\\:';

    @if $bp == 'xs' {
        $prefix: '';
    }

    @include wrap-breakpoint($bp) {
        @each $name, $value in $display {
            $first-char: str-slice($prefix, 1, 1);
            $number: index(('1', '2', '3', '4', '5', '6', '7', '8', '9', '0'), $first-char);

            @if type-of($number) == number {
                $prefix: '\\3#{$prefix}';
            }

            .#{$prefix}#{$name} {
                display: $value;
            }
        }
    }
}
