.label {
    label { /* stylelint-disable-line selector-max-type */
        display: flex;
        align-items: center;
        justify-content: space-between;

        font-weight: $sans-serif-semibold;
        font-size: font-size(sm);
    }
}
