.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: flex;
    justify-content: center;
    overflow-y: auto;
}

.modal-link {
    color: color(grey-800);
    font-weight: $sans-serif-regular;
    font-size: font-size(sm);

    cursor: pointer;

    transition: color 200ms transition-timing();

    &:hover {
        color: color(black);
    }
}

.modal__backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background: color(grey-800);
    opacity: 0.2;
}

.modal__panel {
    position: relative;
    z-index: 100;

    width: 100%;

    margin: auto;

    background-color: color(white);

    border: 1px solid color(grey-400);
    border-radius: border-radius(sm);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.01), 0 6px 40px 0 rgba(0, 0, 0, 0.2);
}

.modal__component {
    width: 100%;
}

.modal__panel--loading {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 720px;
    min-height: 360px;

    .loading-spinner {
        width: 15%;
        height: 100%;
    }
}

.modal__head {
    padding: spacing(9);
}

.modal__body {
    padding: spacing(6) spacing(9);

    > p + * { /* stylelint-disable-line selector-max-type, selector-max-universal */
        margin-top: spacing(4);
    }
}

.modal__body--form {
    padding-bottom: spacing(10);
}

.modal__body + .modal__body,
.modal__head + .modal__body {
    border-top: 1px solid color(grey-300);
}

.modal__foot {
    display: flex;
    justify-content: space-between;
    padding: spacing(5) spacing(9);

    background-color: color(grey-200);
    border-bottom-right-radius: border-radius(sm);
    border-bottom-left-radius: border-radius(sm);
}

.modal__foot-left {
    width: 100%;
}

.modal__foot-right {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    > * + * {  /* stylelint-disable-line selector-max-universal */
        margin-left: spacing(3);
    }
}

@include respond-to('lg') {
    .modal__panel {
        width: unset;
    }

    .modal__component {
        width: rem-calc(720px);
    }
}
