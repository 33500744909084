.change-requests-placeholder {
    min-height: 70px;
}

@include respond-to('2xl') {
    .change-requests-placeholder {
        display: none;
    }
}

.change-requests {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;

    display: flex;
    flex-direction: column;
    width: 100%;

    min-height: 70px;

    background: color(white);
    border: 1px solid color(grey-300);

    > * + * { /* stylelint-disable-line selector-max-universal */
        border-top: 1px solid color(grey-300);
    }
}

.change-requests--open {
    height: 100%;
}

.change-requests--empty {
    .change-requests__body {
        align-items: center;
        justify-content: center;
    }
}

.change-requests__head {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: spacing(6);
}

.change-requests__head-left,
.change-requests__head-right {
    display: flex;
    align-items: center;
}

.change-requests__icon {
    width: 22px;
    height: 22px;

    color: color(grey-500);
}

.change-requests__open-icon {
    width: 15px;
    height: 15px;

    color: color(grey-700);
}

.change-requests__heading {
    margin: 0;
    margin-left: spacing(3);

    font-weight: $sans-serif-semibold;
    font-size: font-size(lg);
}

.change-requests__body {
    display: flex;
    flex: 1;
    flex-direction: column;

    padding: spacing(6);
    overflow-y: auto;

    .notes {
        max-height: 100%;
    }
}

.change-requests__foot {
    padding: spacing(5) spacing(6);
}

.change-requests__compose {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: spacing(4);

    background: color(grey-100);
    border: 1px solid color(grey-500);
    border-radius: border-radius(xs);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);

    .button {
        margin-top: spacing(3);
    }

    .field-group {
        width: 100%;
    }
}

.change-requests-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: spacing(72);

    text-align: center;
}

.change-requests-message__icon {
    width: 56px;

    color: color(grey-500);
}

.change-requests-message__copy {
    margin-top: spacing(3);

    color: color(grey-500);

    font-weight: $sans-serif-semibold;
    font-size: font-size(lg);
}
