.declaration-form {
    display: flex;
    flex-direction: column;
    height: 100%;

    .page-header {
        border: none;
        box-shadow: none;
    }

    .page-header__buttons {
        display: none;
    }

    .declaration-head__subtitle {
        display: none;
    }

    .change-requests {
        z-index: 9;
    }
}

.declaration-head__name {
    overflow: hidden;

    white-space: nowrap;
    text-overflow: ellipsis;
}

.declaration-head__title {
    display: flex;
    flex-wrap: wrap;
    gap: spacing(3);
    align-items: flex-start;
}

.declaration-head,
.page-header__head-left {
    flex: 1;
    overflow: hidden;
}

.declaration-statuses {
    .status-badge + .status-badge {
        margin-left: spacing(1);
    }
}

.declaration-head__subtitle {
    display: flex;
    margin-top: spacing(2);

    color: color(grey-800);
    font-weight: $sans-serif-medium;
    font-size: font-size(sm);

    > * {
        display: flex;
        align-items: center;

        & + * { /* stylelint-disable-line scss/selector-no-redundant-nesting-selector,selector-max-universal */
            margin-left: spacing(3);
        }
    }
}

.declaration-form-layout {
    width: 100%;
}

.declaration-form-layout__form-col {
    overflow-y: auto;
}

.declaration-edit {
    color: color(grey-800);
    text-decoration: none;

    cursor: pointer;
}

.declaration-edit__icon {
    width: 16px;
    height: 16px;

    margin-right: spacing(1);

    color: color(grey-800);
}

.declaration__errors-list {
    margin: 0;

    li {
        + li { /* stylelint-disable-line selector-max-type */
            margin-top: spacing(1);
        }
    }
}

.declaration__sections {
    flex-shrink: 0;

    background-color: color(white);
    border-top: 1px solid color(grey-300);
    border-bottom: 1px solid color(grey-300);
    box-shadow: 0 rem-calc(2px) rem-calc(4px) 0 rgba(0, 0, 0, 0.05);
}

.tabs-container {
    padding-top: spacing(5);
    padding-bottom: spacing(5);
}

@include respond-to('md') {
    .declaration-form {
        .declaration-head__subtitle {
            display: flex;
        }

        .declaration-head__title {
            flex-wrap: nowrap;
            align-items: center;
        }

        .tabs-container {
            overflow-x: auto;
        }
    }
}
@include respond-to('2xl') {
    .declaration-form {
        .page-header {
            padding-bottom: spacing(2);
        }

        .declaration-form-layout__row {
            height: 100%;
            min-height: 750px;
        }

        .change-requests {
            position: static;
        }

        .declaration-form-layout__form-col {
            height: 100%;
        }

        .declaration-form-layout__change-requests-col {
            display: block;

            height: 100%;
            padding-top: spacing(6);
            padding-bottom: spacing(6);
            padding-left: spacing(4);
        }

        .declaration__pagination {
            display: block;
        }

        .page-header__buttons {
            display: flex;
        }

        .declaration-form-layout {
            overflow: auto;
        }

        .page-header--mobile {
            display: none;
        }

        .declaration__sections {
            border-top: none;
        }
    }
}
