.field-group:not(.field-group.field-group--checkbox):not(.repeater-row__body .field-group--textarea, .repeater-row__body .field-group--select) {
    margin-top: spacing(6);
    margin-bottom: spacing(6);
}

.repeater-row__body [wire\:snapshot] + [wire\:snapshot] .label {
    margin-top: spacing(6);
}

.field-group {
    > .label ~ .date-input,
    > .label ~ .input,
    > .label ~ .textarea,
    > .label ~ .checkbox,
    > .label ~ .select {
        margin-top: spacing(3);
    }

    + .field-group {
        margin-top: spacing(6);
    }

    + .label {
        margin-top: spacing(6);
    }

    + .description {
        margin-top: spacing(6);
    }
}

.label + .field-group {
    margin-top: spacing(6);
}

.description + .field-group {
    margin-top: spacing(3);
}

.heading-form + .label + .field-group,
.heading-form + .description + .field-group {
    margin-top: spacing(6);
}

.field-group--checkbox {
    + .field-group--checkbox {
        margin-top: spacing(2);
    }
}

.field-group__input {
    display: block;
    width: 100%;
}

.field-group--error {
    .field-group__label,
    .description {
        color: color(red-600);
    }

    .input--datepicker input,
    .vue-tel-input,
    .field-group__input,
    .autocomplete-input {
        background: color(red-100);
        border-color: color(red-600);

        &::placeholder { /* stylelint-disable-line selector-max-type */
            color: color(red-600);
        }

        input { /* stylelint-disable-line selector-max-type */
            background: color(red-100);
        }
    }
}

.field-group--warning {
    .field-group__label {
        color: color(orange-600);
    }

    .input--datepicker input,
    .vue-tel-input,
    .field-group__input,
    .autocomplete-input {
        background: color(orange-100);
        border-color: color(orange-600);

        &::placeholder { /* stylelint-disable-line selector-max-type */
            color: color(orange-600);
        }

        input { /* stylelint-disable-line selector-max-type */
            background: color(orange-100);
        }
    }
}

.field-group--required {
    .label__title {
        &:after {
            display: inline-block;
            margin-left: spacing(1);

            color: color(red-600);

            content: '*';
        }
    }
}

.field-group--inline {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .label--inline {
        flex-basis: 40%;
        margin: 0;
        padding-right: spacing(6);

        text-align: right;

        label { /* stylelint-disable-line selector-max-type */
            justify-content: flex-end;
            margin-bottom: 0;
        }
    }

    .select,
    .input {
        flex-basis: 60%;
    }

    .field-group__input {
        margin: 0;
    }

    .field-group__error {
        margin-left: 40%;
    }
}

.field-group__error {
    margin: spacing(2) 0 0 0;

    color: color(red-600);
}

.field-group__warning {
    margin: spacing(2) 0 0 0;

    color: color(orange-600);
}

.field-group-seperator {
    margin: spacing(4);
    margin-bottom: 0;

    border: 0;
    border-top: solid 1px color(grey-200);
}