@function spacing($value) {
    $spacing: map-get($spacing-config, $value);

    @if $spacing {
        @return $spacing;
    }

    @error 'Could not find spacing with name `#{$value}`';
}
@function section-padding($breakpoint-name, $key: 'default') {
    $spacing: breakpoint-config($breakpoint-name, section-padding);

    @return map-get($spacing, $key);
}

/**
 * Get a value out of the "layout" config for a breakpoint
 *
 * Usage examples:
 * padding-left: layout(md, 'gutters');
 */
@function layout($breakpoint-name, $key) {
    $layout: breakpoint-config($breakpoint-name, layout);

    @return map-get($layout, $key);
}
