/* stylelint-disable declaration-no-important */

.hidden {
    display: none !important;
}

.visible {
    display: block !important;
}
/* stylelint-enable declaration-no-important */
