.header-button {

    max-width: 50%;
    padding: spacing(4);

    border: 1px solid color(grey-800);
}

.header-button__title {
    font-weight: bold;
    text-decoration: none;
}

.header-button__description {
    color: color(grey-800);
    text-decoration: none;
}
