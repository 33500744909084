.filters {
    display: flex;
    flex-direction: column;
    gap: spacing(3);
    align-items: center;
    justify-content: flex-end;

    margin-top: spacing(3);

    .dropdown {
        width: 100%;
    }

    .dropdown__trigger {
        width: 100%;
    }

    .dropdown-button {
        width: 100%;
    }
}

.filters__clear {
    font-size: font-size(sm);
}

@include respond-to('2xl') {
    .filters {
        flex-direction: row;

        margin-top: 0;

        .dropdown {
            width: auto;
        }
    }
}
