.notification {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.notification__icon {
    margin-bottom: spacing(6);

    color: color('green-600');
}

.notification__heading {
    margin-bottom: spacing(4);

    font-weight: $sans-serif-medium;
    font-size: font-size('2xl');

    letter-spacing: -0.4px;

    text-align: center;
}

.notification__subheading {
    font-size: font-size('xl');

    letter-spacing: -0.4px;

    text-align: center;
}
