.panel {
    background-color: color(white);
    border: solid rem-calc(1px) color(grey-200);
    box-shadow: 0 rem-calc(2px) rem-calc(4px) 0 rgba(0, 0, 0, 0.05);
}

.panel__head {
    padding: spacing(6) spacing(8);

    text-align: center;
}

.panel__body {
    padding: spacing(8);
}

.panel__head + .panel__head,
.panel__body + .panel__body,
.panel__head + .panel__body,
.panel__body + .panel__head {
    border-top: solid rem-calc(1px) color(grey-300);
}
