/* stylelint-disable declaration-no-important */

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.text-center {
    text-align: center !important;
}

.uppercase {
    text-transform: uppercase !important;
}

.lowercase {
    text-transform: lowercase !important;
}

.capitalize {
    text-transform: capitalize !important;
}

/* stylelint-enable declaration-no-important */
