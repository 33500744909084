%button {
    position: relative;

    display: inline-block;

    padding: spacing(3) spacing(5);

    font-weight: $sans-serif-semibold;

    font-size: font-size(sm);
    line-height: 1.21;

    white-space: nowrap;

    text-align: center;
    text-decoration: none;

    border: none;
    border-radius: 4px;

    cursor: pointer;

    transition: color 200ms transition-timing(), background-color 200ms transition-timing(), border-color 200ms transition-timing();

    &:disabled {
        color: color(white);

        background-color: color(grey-600);
        border-color: color(grey-600);

        cursor: no-drop;

        &:hover {
            color: color(white);

            background-color: color(grey-600);
            border-color: color(grey-600);
        }
    }
}

.button {
    /* stylelint-disable-next-line at-rule-blacklist */
    @extend %button;
    color: color(white);

    background-color: color(green-500);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);

    &:hover {
        text-decoration: none;

        background-color: color(green-600);
    }
}

.button--dark {
    background-color: color(green-900);
    border: 0;

    &:hover {
        background-color: color(black);
    }
}

.button--light {
    color: color(black);

    background-color: color(white);
    border: 1px solid color(grey-500);
    box-shadow: none;

    &:hover {
        color: color(grey-800);

        background-color: color(white);

        border-color: color(grey-800);
    }
}

.button--full {
    width: 100%;
}

.button--lg {
    width: 100%;
    padding: spacing(5);
}

.button--with-icon {
    display: flex;
    align-items: center;

    padding-left: spacing(3);

    svg { /* stylelint-disable-line selector-max-type */
        width: 20px;
        height: 20px;

        margin-right: spacing(1);
    }
}

.button--with-icon-only {
    display: flex;
    align-items: center;

    padding: spacing(3);

    color: color(black);

    background-color: color(white);
    border: 1px solid color(grey-500);
    box-shadow: none;

    svg { /* stylelint-disable-line selector-max-type */
        width: 15px;
        height: 15px;
    }

    &.button--sm {
        padding: spacing(1);
    }

    &:hover {
        color: color(grey-800);

        background-color: color(white);

        border-color: color(grey-800);
    }
}

a.button--with-icon-only { /* stylelint-disable-line selector-max-type,selector-no-qualifying-type */
    width: fit-content;
}

.dropdown-button {
    /* stylelint-disable-next-line at-rule-blacklist */
    @extend %button;

    padding: spacing(3) spacing(8) spacing(3) spacing(4);

    color: color(black);

    background-color: color(white);
    border: 1px solid color(grey-500);
    box-shadow: none;

    &:hover {
        color: color(grey-800);

        background-color: color(white);

        border-color: color(grey-800);
    }
}

.dropdown__trigger--active {
    .dropdown-button {
        color: color(grey-800);

        background-color: color(white);

        border-color: color(grey-800);
    }
}

.dropdown-button__icon {
    position: absolute;

    top: 50%;
    right: spacing(3);

    width: spacing(3);
    height: spacing(3);

    transform: translateY(-50%);

    pointer-events: none;
}

.suspend-button,
.delete-button {
    @extend %button; /* stylelint-disable-line at-rule-blacklist */
    padding-right: spacing(3);

    padding-left: spacing(6);

    color: color(red-600);

    background: color(white);

    &:focus,
    &:hover {
        color: color(red-800);

        outline: none;
    }
}

.suspend-button__icon,
.delete-button__icon {
    position: absolute;
    top: 0;
    bottom: 0;
    left: spacing(2);

    width: 10px;
    height: 100%;
}

.repeater-add-button {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;

    padding: spacing(6);

    font-weight: $sans-serif-semibold;
    font-size: font-size(base);

    background: none;
    border: 1px dashed color(grey-800);

    border-radius: border-radius(sm);

    cursor: pointer;

    opacity: 1;

    transition: opacity 200ms transition-timing();

    appearance: none;

    &:hover {
        opacity: 0.5;
    }
}

.repeater-delete-button {
    padding-right: 0;
}

.repeater-add-button__icon {
    width: 12px;

    margin-right: spacing(2);

    color: color(grey-800);
}
