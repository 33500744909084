/* stylelint-disable declaration-no-important */
.overflow-auto {
    overflow: auto !important;
}

.overflow-hidden {
    overflow: hidden !important;
}

/* stylelint-enable declaration-no-important */
