.table {
    width: 100%;

    font-size: font-size(sm);

    background: color(white);

    border-collapse: collapse;

    /* stylelint-disable selector-max-type */
    th {
        padding: spacing(5);

        font-weight: $sans-serif-semibold;

        text-align: left;
    }

    tr {
        border: solid rem-calc(1px) color(grey-300);
    }

    td {
        padding: spacing(4);

        text-align: left;

        a {
            text-decoration: none;
        }
    }

    thead {
        border: 1px solid color(grey-300);
        box-shadow: 0 rem-calc(2px) rem-calc(4px) 0 rgba(0, 0, 0, 0.04);
    }
}

.table__sort-link {
    display: flex;
    align-items: center;
}

.table__sort-arrow {
    width: 10px;
    margin-left: 0.2em;

    color: color(grey-800);

    transition: transform 200ms transition-timing();
}

.table__actions-arrow {
    width: 7px;

    color: color(grey-700);

    transition: transform 200ms transition-timing();
}

.table__clickable-row {
    cursor: pointer;

    transition: background 200ms transition-timing();

    &:hover {
        background: color(grey-100);

        .table__actions-arrow {
            transform: translateX(spacing(1));
        }
    }
}

.responsive-table {
    overflow-x: auto;
}
