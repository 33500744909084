$spacing-config: (
    0: 0,
    px: 1px,
    // 0.5: 2px,
    1: rem-calc(4px),
    // 1.5: rem-calc(6px),
    2: rem-calc(8px),
    // 2.5: rem-calc(10px),
    3: rem-calc(12px),
    // 3.5: rem-calc(14px),
    4: rem-calc(16px),
    5: rem-calc(20px),
    6: rem-calc(24px),
    7: rem-calc(28px),
    8: rem-calc(32px),
    9: rem-calc(36px),
    10: rem-calc(40px),
    11: rem-calc(44px),
    12: rem-calc(48px),
    14: rem-calc(56px),
    16: rem-calc(64px),
    18: rem-calc(72px),
    20: rem-calc(80px),
    24: rem-calc(96px),
    28: rem-calc(112px),
    32: rem-calc(128px),
    36: rem-calc(144px),
    40: rem-calc(160px),
    44: rem-calc(176px),
    48: rem-calc(192px),
    52: rem-calc(208px),
    56: rem-calc(224px),
    60: rem-calc(240px),
    64: rem-calc(256px),
    72: rem-calc(288px),
    80: rem-calc(320px),
    96: rem-calc(384px),
);
