.pagination {
    display: flex;
    align-items: center;
    justify-content: center;

    margin: spacing(5) 0;

    > * + * { /* stylelint-disable-line selector-max-universal */
        margin-left: spacing(8);
    }
}

.pagination__link {
    /* stylelint-disable-next-line at-rule-blacklist */
    @extend %button;

    position: relative;

    display: flex;
    align-items: center;

    padding: spacing(3) spacing(5);

    color: color(black);
    font-weight: $sans-serif-semibold;
    font-size: font-size(sm);

    background-color: color(white);
    border: 1px solid color(grey-500);

    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);

    transition: color 200ms transition-timing(), background-color  200ms transition-timing(), border-color 200ms transition-timing();

    &:hover {
        color: color(grey-800);

        background-color: color(white);

        border-color: color(grey-800);
    }
}

.pagination__link-icon {
    position: absolute;

    height: 12px;
}

.pagination__link--prev {
    padding-left: spacing(11);

    .pagination__link-icon {
        left: spacing(4);
    }
}

.pagination__link--next {
    padding-right: spacing(11);

    .pagination__link-icon {
        right: spacing(4);
    }
}

.pagination__link--inactive {
    color: color(grey-400);

    background: color(white);

    border: 1px solid color(grey-300);
    cursor: no-drop;

    &:hover {
        color: color(grey-400);

        background: color(white);

        border: 1px solid color(grey-300);
    }
}

.pagination__count {
    color: color(black);
    font-weight: $sans-serif-semibold;
}
