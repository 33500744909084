/* stylelint-disable max-nesting-depth */
@mixin output-utilities($config, $group-config) {
    // Loop over each breakpoint
    @each $bp in breakpoint-names() {
        $prefix: '#{$bp}\\:';

        @if $bp == 'xs' {
            $prefix: '';
        }

        @include wrap-breakpoint($bp) {
            // Loop over each spacing size
            @each $name, $size in $group-config {
                // Loop over each property
                @each $class, $properties in $config {
                    $first-char: str-slice($prefix, 1, 1);
                    $number: index(('1', '2', '3', '4', '5', '6', '7', '8', '9', '0'), $first-char);

                    @if type-of($number) == number {
                        $prefix: '\\3#{$prefix}';
                    }

                    .#{$prefix}#{$class}-#{$name + ''} {
                        @each $property in $properties {
                            #{$property}: $size;
                        }
                    }
                }
            }
        }
    }
}
/* stylelint-enable max-nesting-depth */
