.alerts {
    position: absolute;
    right: spacing(6);
    bottom: spacing(6);
    z-index: 10;
}

@keyframes alert-fade-in {
    from {
        transform: translateY(100px);
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.alerts__list {
    margin: 0;
    padding: 0;

    list-style: none;
}

.alerts__alert {
    position: relative;

    min-width: 300px;
    max-width: 350px;

    padding: spacing(4) spacing(11);

    background: color(white);
    border: 1px solid color(grey-400);

    border-radius: border-radius(sm);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.01), 0 6px 10px 0 rgba(0, 0, 0, 0.09);

    + .alerts__alert {
        margin-top: spacing(2);
    }

    opacity: 0;

    animation: alert-fade-in 1s transition-timing() 200ms 1 normal forwards;
}

.alerts__heading {
    display: flex;
    align-items: center;
}

.alerts__message {
    margin-top: spacing(1);

    color: color(grey-800);
    font-size: font-size(sm);
}

.alerts__icon {
    position: absolute;
    left: 1rem;

    display: inline-block;
    width: 18px;
    height: 18px;
}

.alerts__icon--success {
    color: color(green-600);
}

.alerts__icon--error {
    color: color(red-600);
}

.alerts__heading-copy {
    margin: 0;

    font-weight: $sans-serif-semibold;
}

.alerts__close {
    position: absolute;
    top: spacing(3);
    right: spacing(3);

    width: rem-calc(10px);
    height: rem-calc(10px);

    color: color(grey-800);

    background: none;

    border: none;
    cursor: pointer;

    appearance: none;
    user-select: none;

    &:hover {
        opacity: 0.75;
    }
}
