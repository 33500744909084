.checkbox {
    display: inline-block;
    width: 100%;

    background-color: color(white);
    border: 1px solid color(grey-400);
    border-radius: 4px;
    margin-top: 7px;
    margin-right: 5px;

    input { /* stylelint-disable-line selector-max-type */
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        margin: 0;

        background: color(white);
        border: 1px solid color(grey-400);
        border-radius: 4px;
        cursor: pointer;

        appearance: none;

        &:checked { /* stylelint-disable-line selector-max-type */
            color: color(green-500);

            background: url('/images/icons/checkbox/checkbox-tick.svg') no-repeat center;
            background-color: color(white);
            background-size: 12px;
            border-color: color(green-500);
        }
    }

    .checkbox__label {
        display: flex;
        align-items: center;
        margin: 0;

        padding: spacing(3) spacing(4);

        cursor: pointer;
    }

    .checkbox__title {
        margin-left: spacing(4);

        font-weight: $sans-serif-semibold;
        font-size: font-size(sm);
        line-height: 1.5em;
    }
}

.checkbox--bold {
    .checkbox__label {
        font-weight: $sans-serif-semibold;
    }
}

.checkbox--inline {
    border: none;

    .checkbox__label {
        padding: 0;
        padding-left: 0.75rem;
    }

    .checkbox__title {
        display: none;
    }
}

.checkbox--disabled {
    .checkbox__label {
        cursor: no-drop;
    }

    input { /* stylelint-disable-line selector-max-type */
        cursor: no-drop;
    }
}
