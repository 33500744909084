.label + .radio-button {
    margin-top: spacing(3);
}

.radio-button {
    + .radio-button {
        margin-top: spacing(2);
    }

    display: inline-block;
    width: 100%;

    background-color: color(white);
    border: 1px solid color(grey-400);
    border-radius: 4px;

    .radio-button__input { /* stylelint-disable-line selector-max-type */
        width: 24px;
        height: 24px;
        margin: 0;

        background: color(white);
        border: 1px solid color(grey-400);
        border-radius: border-radius(lg);

        cursor: pointer;

        appearance: none;

        &:checked { /* stylelint-disable-line selector-max-type */
            color: color(green-500);

            background: url('/images/icons/radio-button/checked.svg') no-repeat center;
            background-color: color(white);
            background-size: 12px;
            border-color: color(green-500);
        }
    }

    .radio-button__label {
        display: flex;
        align-items: center;
        margin: 0;
        padding: spacing(3) spacing(4);

        cursor: pointer;
    }

    .radio-button__title {
        margin-left: spacing(4);

        font-weight: $sans-serif-semibold;
        font-size: font-size(sm);
    }
}

.radio-button--disabled {
    .radio-button__label {
        cursor: no-drop;
    }

    .radio-button__input {
        cursor: no-drop;
    }
}
