[data-tooltip] {
    position: relative;
}

[data-tooltip]:before {
    position: absolute;
    bottom: 100%;
    left: 50%;
    z-index: 1;

    margin-bottom: spacing(2);

    padding: spacing(2) spacing(4);

    color: color(white);
    font-weight: $sans-serif-semibold;
    font-size: font-size(base);
    white-space: nowrap;

    background-color: color(grey-800);
    border-radius: border-radius(sm);

    transform: translateX(-50%);

    opacity: 0;

    content: attr(data-tooltip);

    pointer-events: none;
}

[data-tooltip]:hover:before {
    opacity: 1;

    transition: opacity 200ms transition-timing() 500ms;
}
