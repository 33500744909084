.nav {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    min-height: $mobile-nav-height;

    background: color(white);
    border-bottom: 1px solid color(grey-300);
}

.nav__left,
.nav__right {
    display: flex;
    align-items: center;
    height: 100%;
}

.nav__left {
    padding-left: spacing(4);

    > * + * { /* stylelint-disable-line selector-max-universal */
        margin-left: spacing(5);
        padding-left: spacing(5);

        border-left: 1px solid color(grey-400);
    }
}

.nav__items {
    display: none;

    > * + * { /* stylelint-disable-line selector-max-universal */
        border-left: 1px solid color(grey-300);
    }
}

.nav-item {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    padding: 0 spacing(6);

    font-weight: $sans-serif-medium;
    font-size: font-size(sm);
    text-decoration: none;

    cursor: pointer;

    user-select: none;

    &:hover {
        .nav-item__icon,
        .nav-item__copy {
            color: color(green-600);
        }

        text-decoration: none;

        &:after {
            opacity: 1;
        }
    }

    &:after {
        position: absolute;
        right: 0;
        bottom: -1px;
        left: 0;

        width: 100%;

        border-bottom: 1px solid color(green-600);
        opacity: 0;

        transition: opacity 200ms transition-timing();

        content: '';
    }
}

.nav-account {
    height: 100%;

    color: color(grey-700);
    text-decoration: none;

    cursor: pointer;

    user-select: none;

    .dropdown__trigger {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding: 0 spacing(3);
    }
}

.nav-account__icon {
    width: 28px;
    height: 28px;
}

.dropdown__trigger--active {
    .nav-account__icon {
        color: color(green-600);
    }
}

.nav-account__popup {
    position: absolute;
}

.nav-item__icon {
    width: 18px;
    height: 18px;

    color: color(grey-700);

    transition: color 200ms transition-timing();
}

.nav-item__copy {
    margin-left: spacing(2);

    color: color(black);

    transition: color 200ms transition-timing();
}

.nav-item--active {
    .nav-item__copy,
    .nav-item__icon {
        color: color(green-600);
    }

    &:after {
        opacity: 1;
    }
}

.nav-mobile {
    display: flex;
    align-items: center;
    height: 100%;
}

.nav-mobile__trigger {
    position: relative;

    display: flex;
    align-items: center;

    height: 100%;

    padding-right: spacing(4);
    padding-left: spacing(4);

    cursor: pointer;

    user-select: none;
}

.nav-mobile__copy {
    margin-left: spacing(3);

    font-weight: $sans-serif-medium;
    font-size: font-size(sm);
}

.nav-mobile__icon {
    width: 16px;
    height: 16px;

    color: color(grey-600);

    pointer-events: none;
}

.nav-mobile__items {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin-top: $mobile-nav-height;

    padding: spacing(5);

    text-align: center;

    background: color(white);

    > * + * { /* stylelint-disable-line selector-max-universal */
        border-top: 1px solid color(grey-300);
    }
}

.nav-mobile__item {
    width: 100%;
    max-width: 500px;
    padding: spacing(5);

    color: color(black);

    font-size: font-size(base);
    text-decoration: none;

    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

.nav-mobile__form {
    width: 100%;
    max-width: 500px;

    .nav-mobile__item {
        background: none;
        border: none;
    }
}

.nav__buttons {
    display: none;
}

.logo {
    display: flex;
    align-items: center;

    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
}

.logo__image {
    width: 31px;
    height: 31px;
}

.logo__copy {
    display: none;
}

.logo__heading {
    margin: 0;

    font-weight: $sans-serif-bold;
    font-size: font-size(base);
}

.logo__sub-heading {
    margin: 0;

    font-size: font-size(sm);
}

.initiative {
    display: none;
}

.initiative__copy {
    font-weight: $sans-serif-regular;
    font-size: font-size(sm);
}

.initiative__logo {
    height: 11px;
}

.initiative + .initiative {
    margin-left: spacing(6);
    padding-left: spacing(6);

    border-left: 1px solid #dde0e6;
}

@include respond-to('md') { /* stylelint-disable-line rareloop/no-nested-breakpoints */
    .nav {
        min-height: $tablet-nav-height;
    }

    .logo__image {
        width: 37px;
        height: 37px;
    }

    .logo__copy {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: spacing(3);
    }

    .logo__sub-heading {
        margin-left: spacing(3);
    }

    .nav-mobile__items {
        margin-top: $tablet-nav-height;
    }
}
@include respond-to('2xl') { /* stylelint-disable-line rareloop/no-nested-breakpoints */
    .nav__items {
        display: flex;
        align-items: center;
        height: 100%;
    }

    .nav__buttons {
        display: block;
        padding-right: spacing(4);

        > * + * { /* stylelint-disable-line selector-max-universal */
            margin-left: spacing(3);
        }
    }

    .logo__copy {
        flex-direction: column;
        align-items: flex-start;
    }

    .logo__sub-heading {
        margin-left: 0;

        color: color(grey-800);
        font-size: font-size(xs);
    }

    .initiative {
        display: flex;
        align-items: center;

        padding-top: spacing(2);
        padding-bottom: spacing(2);

        > * + * { /* stylelint-disable-line selector-max-universal */
            margin-left: spacing(1);
        }
    }

    .initiative--large-screen-only {
        display: none;
    }

    .nav-mobile {
        display: none;
    }
}
@include respond-to('4xl') { /* stylelint-disable-line rareloop/no-nested-breakpoints */
    .initiative--large-screen-only {
        display: block;
    }
}
