.search {
    position: relative;

    width: 100%;

    input { /* stylelint-disable-line selector-max-type */
        width: 100%;
        height: 43px;
        padding: spacing(2);

        padding-left: spacing(10);

        color: color(black);

        background: color(white);
        border: 1px solid color(grey-400);
        border-radius: 4px;

        &:disabled { /* stylelint-disable-line selector-max-type */
            color: color(grey-900);

            cursor: no-drop;
        }
    }

    .search__icon {
        position: absolute;

        display: flex;
        align-items: center;
        justify-content: center;

        width: spacing(10);

        height: 100%;

        color: color(grey-800);

        svg { /* stylelint-disable-line selector-max-type */
            height: 17px;
        }
    }
}
