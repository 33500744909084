.page-footer {
    display: none; // Disabled for now
    min-height: 80px;
    margin-top: spacing(8);
    padding: spacing(6) 0;

    background-color: color(white);
    border-top: 1px solid color(grey-300);
    border-top: 1px solid #eceef0;
    box-shadow: 0 -2px 9px 0 rgba(0, 0, 0, 0.05);
}

.declaration__page-footer {
    margin-top: 0;
}

.page-footer__content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.page-footer__logo {
    height: 30px;
}

@include respond-to('2xl') {
    .declaration__page-footer {
        box-shadow: none;
    }

    .page-footer__content {
        justify-content: flex-start;
    }
}
